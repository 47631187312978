import React from "react";
import emptyMailbox from "../../assets/emptyMailbox.png";
import logo from "../../assets/blue-logo.png";
import { useAuth0 } from "react-auth0-spa";
import "./PasswordReset.css";

const PasswordResetSuccessPage = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div class="reset-container">
      <div class="reset-header">
        <div class="logo">
          <img src={logo} alt="logo" />
        </div>
        <div class="icon">
          <img src={emptyMailbox} alt="empty mailbox icon" />
        </div>
      </div>
      <div class="reset-body">
        <h1>Password Reset Email Sent</h1>
        <p>
          Please check your email for instructions for resetting your password.
        </p>
      </div>
      <button className="btn-primary" onClick={loginWithRedirect}>
        Log In
      </button>
    </div>
  );
};

export default PasswordResetSuccessPage;
