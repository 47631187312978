// features/MsalPrivateRoute.js
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useIsAuthenticated as useMsalIsAuthenticated } from "@azure/msal-react";

const MsalPrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useMsalIsAuthenticated();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/provider" />
      }
    />
  );
};

export default MsalPrivateRoute;
