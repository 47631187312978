import React, { useState } from "react";
import { FormError } from "./error";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";

export const TimerTextField = ({ field, errors, value, ...restProps }) => {
  const [open, setOpen] = useState(false);

  // Ensure fieldValue is always defined
  const fieldValue = field?.value || null;

  return (
    <>
      {field?.name && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopTimePicker
            open={open}
            onClose={() => setOpen(false)}
            value={fieldValue}
            onChange={(event) => field?.onChange(event)}
            onBlur={(event) => field?.onBlur(event)}
            {...restProps}
            slotProps={{
              textField: {
                onClick: () => setOpen(true),
                readOnly: true,
              },
            }}
            timeSteps={{ minutes: 1 }}
          />
        </LocalizationProvider>
      )}
      {field?.name && <FormError name={field.name} errors={errors} />}
    </>
  );
};
