import React, { useState } from "react";
import { FormError } from "./error";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";

export const NumberField = ({ field, errors, value, ...restProps }) => {
  const [open, setOpen] = useState(false);

  // Ensure fieldValue is always defined
  const fieldValue = field?.value || null;

  return (
    <>
      {field?.name && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TextField
            open={open}
            onClose={() => setOpen(false)}
            value={fieldValue}
            type="number"
            onChange={(event) => field?.onChange(event)}
            onBlur={(event) => field?.onBlur(event)}
            {...restProps}
          />
        </LocalizationProvider>
      )}
      {field?.name && <FormError name={field.name} errors={errors} />}
    </>
  );
};
