import { Typography } from "antd";
import React from "react";
import "./footer.css";

export default function Footer() {
  return (
    <div className="bi-portal-footer-container">
      <div className="bi-portal-footer">
        <Typography>
          <p className="para-1">Copyright &#169;2023 Cortica</p>
          <p className="para-2">All Rights Reserved</p>
        </Typography>
      </div>
    </div>
  );
}
