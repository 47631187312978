import { Typography } from "@mui/material";
import React from "react";
import "./errorMessage.css";
import Warning from "assets/Warning.svg"

const MileageError: React.FC = () => {
  return (
    <div className="drive-error-message-container">
      <div className="drive-error-message">
      <img src={Warning} className="pad-right-7" alt="Your SVG" />
        <Typography> 
          <p className="drive-error-message-1">
            Input mileage before confirming your appointments.
          </p>
        </Typography>
      </div>
    </div>
  );
};

export default React.memo(MileageError);
