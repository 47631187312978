import { DRIVETIME_APPT_IDS } from "constant/BiPortalConstants";
import dayjs from "dayjs";
import moment from "moment";
import * as Yup from "yup";

const COMMON_VALIDATORS = {
  selectedDay: Yup.string().nullable().required("Date is required"),
  startDate: Yup.string()
    .nullable()
    .required("Start time is required")
    .typeError("Start time is required"),
  endDate: Yup.string()
    .nullable()
    .required("End time is required")
    .typeError("End time is required"),
  startingAddress: Yup.string().when("appointmentType", {
    is: (appointmentType: number) =>
      DRIVETIME_APPT_IDS.includes(appointmentType),
    then: Yup.string()
      .nullable()
      .required(
        "Select an address from the suggestions after clicking and typing in the field."
      ),
    otherwise: Yup.string().nullable(),
  }),
  destinationAddress: Yup.string().when("appointmentType", {
    is: (appointmentType: number) =>
      DRIVETIME_APPT_IDS.includes(appointmentType),

    then: Yup.string()
      .nullable()
      .required(
        "Select an address from the suggestions after clicking and typing in the field."
      ),
    otherwise: Yup.string().nullable(),
  }),
  miles: Yup.number().nullable(),
  reimburseableMiles: Yup.number().nullable(),

  isFirstLastDrive: Yup.boolean().when("appointmentType", {
    is: (appointmentType: number) =>
      DRIVETIME_APPT_IDS.includes(appointmentType),
    then: Yup.boolean().nullable().required("Select Yes/No"),
    otherwise: Yup.boolean().nullable(),
  }),
  note: Yup.string().nullable(),
};

const APPOINTMENT_COMMON_VALIDATORS = {
  id: Yup.number().nullable(),
  provider: Yup.object({
    id: Yup.number().nullable().required("Provider is required"),
  }),
  clinic: Yup.object({
    id: Yup.number().nullable().required("Clinic network is required"),
  }),
  appointmentType: Yup.number()
    .nullable()
    .required("Appointment Type is required"),
  recurrencePattern: Yup.object({
    recurringEvery: Yup.string().nullable(),
    recurringDaysArray: Yup.array().nullable(),
  }),
  cancellationReason: Yup.string()
    .nullable()
    .required("Cancellation Reason is required"),
};

export const APPOINTMENT_ADD_SCHEMA = Yup.object({
  selectedDay: COMMON_VALIDATORS.selectedDay,
  startDate: COMMON_VALIDATORS.startDate,
  endDate: COMMON_VALIDATORS.endDate,
  startingAddress: COMMON_VALIDATORS.startingAddress,
  destinationAddress: COMMON_VALIDATORS.destinationAddress,
  appointmentType: APPOINTMENT_COMMON_VALIDATORS.appointmentType,
  miles: COMMON_VALIDATORS.miles,
  reimburseableMiles: COMMON_VALIDATORS.reimburseableMiles,
  isFirstLastDrive: COMMON_VALIDATORS.isFirstLastDrive,
  note: COMMON_VALIDATORS.note,
});

export const CLIENT_EDIT_APPOINTMENT_SCHEMA = Yup.object({
  origialStart: Yup.string().nullable().required(),
  originalEnd: Yup.string().nullable().required(),
  startTime: Yup.string().nullable().required("Start time is required"),
  endTime: Yup.string().nullable().required("End time is required"),
  startReason: Yup.string()
    .when(["origialStart", "startTime"], {
      is: (origialStart: string, startTime: string) =>
        moment(origialStart).toISOString() !== moment(startTime).toISOString(),
      then: Yup.string()
        .nullable()
        .required("Select a reason for changing start time"),
      otherwise: Yup.string().nullable(),
    })
    .nullable(),
  endReason: Yup.string()
    .when(["originalEnd", "endTime"], {
      is: (originalEnd: string, endTime: string) =>
        moment(originalEnd).toISOString() !== moment(endTime).toISOString(),
      then: Yup.string()
        .nullable()
        .required("Select a reason for changing end time"),
      otherwise: Yup.string().nullable(),
    })
    .nullable(),
  note: COMMON_VALIDATORS.note,
});

export const ADMIN_APPOINTMENT_EDIT_SCHEMA = Yup.object({
  origialStart: Yup.string().nullable(),
  originalEnd: Yup.string().nullable(),
  startTime: Yup.string().nullable().required("Start time is required"),
  endTime: Yup.string().nullable().required("End time is required"),
  // reason: Yup.string().nullable().required("Reason is required"),
  reason: Yup.string()
    .when(["originalEnd", "endTime", "origialStart", "startTime"], {
      is: (
        originalEnd: string,
        endTime: string,
        origialStart: string,
        startTime: string
      ) =>
        moment(originalEnd).toISOString() !== moment(endTime).toISOString() ||
        moment(origialStart).toISOString() !== moment(startTime).toISOString(),
      then: Yup.string().nullable().required("Reason is required"),
      otherwise: Yup.string().nullable(),
    })
    .nullable(),
  startingAddress: COMMON_VALIDATORS.startingAddress,
  destinationAddress: COMMON_VALIDATORS.destinationAddress,
  appointmentType: APPOINTMENT_COMMON_VALIDATORS.appointmentType,
  miles: COMMON_VALIDATORS.miles,
  reimburseableMiles: COMMON_VALIDATORS.reimburseableMiles,
  isFirstLastDrive: COMMON_VALIDATORS.isFirstLastDrive,
  note: COMMON_VALIDATORS.note,
});

export const OBSERVER_APPOINTMENT_SCHEMA = Yup.object({
  originalStart: Yup.string().nullable(),
  originalEnd: Yup.string().nullable(),
  startTime: Yup.string()
    .nullable()
    .required("Start time is required")
    .test(
      "is-after-originalStart",
      "Start time cannot be before lead start time",
      function (value) {
        const { originalStart } = this.parent;
        if (!originalStart || !value) return true; // Skip validation if originalStart or value is null
        return (
          dayjs(value).isAfter(dayjs(originalStart)) ||
          dayjs(value).isSame(dayjs(originalStart))
        );
      }
    ),
  endTime: Yup.string()
    .nullable()
    .required("End time is required")
    .test(
      "is-before-originalEnd",
      "End time cannot be after lead end time",
      function (value) {
        const { originalEnd } = this.parent;
        if (!originalEnd || !value) return true; // Skip validation if originalEnd or value is null
        return (
          dayjs(value).isBefore(dayjs(originalEnd)) ||
          dayjs(value).isSame(dayjs(originalEnd))
        );
      }
    ),
});

export const CANCEL_APPOINTMENT_SCHEMA = Yup.object({
  cancellationId: APPOINTMENT_COMMON_VALIDATORS.cancellationReason,
  note: COMMON_VALIDATORS.note,
});
