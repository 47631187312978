export function getFromCache(cacheField) {
  const cachedItem = JSON.parse(localStorage.getItem(cacheField));
  if (cachedItem && isStillValid(cachedItem.storedDate, cachedItem.period)) {
    return cachedItem.item;
  }
  return null;
}

export function cache(cacheField, str, period = 1000 * 30) {
  /// period format is milli seconds
  /// assuming default period of 1 hour
  localStorage.setItem(
    cacheField,
    JSON.stringify({
      item: str,
      period: period,
      storedDate: new Date(),
    })
  );
}

function isStillValid(storedDate, period) {
  if (!storedDate || !period) return false;
  // console.log("[isStillValid] new Date()",new Date())
  // console.log("[storedDate] new Date(storedDate)",new Date(storedDate))
  // console.log("[storedDate] period",period)
  if (new Date() - new Date(storedDate) < period) return true;
  return false;
}
