import { DatePicker } from "antd";
import React from "react";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";

// interface RHFDatePickerFieldProps {
//   control: Control<any>;
//   name: string;
//   placeholder?: string;
// }

const RCDatePickerField = (props) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      rules={{
        required: "This field is required",
      }}
      render={({ field, fieldState }) => {
        return (
          <>
            <DatePicker
              inputReadOnly={true}
              placeholder={props.placeholder}
              status={fieldState.error ? "error" : undefined}
              ref={field.ref}
              name={field.name}
              onBlur={field.onBlur}
              value={field.value ? dayjs(field.value) : null}
              format="MM/DD/YYYY"
              onChange={(date) => {
                field.onChange(date ? date : null);
              }}
            />
            <br />
            {fieldState.error ? (
              <span style={{ color: "red" }}>{fieldState.error?.message}</span>
            ) : null}
          </>
        );
      }}
    />
  );
};

export default React.memo(RCDatePickerField);
