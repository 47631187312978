import { useMsal } from "@azure/msal-react";
import { loginRequest } from "msal/msal-config";
import { useState } from "react";
import axios from "axios";
import { apiRequest } from "features/Appointments/biportal/AppointmentForms/AllAppointmentsApi";
import { useHistory } from "react-router-dom";

const Configuration = (token: string) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

const useSubmitApi = () => {
  const { instance } = useMsal();
  const [error, setError] = useState<string | null>(null);
  const history = useHistory();

  const getToken = async () => {
    const activeAccount = instance.getActiveAccount();
    if (!activeAccount) {
      setError("[AuthHook] No active account found.");
      return null;
    }

    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: activeAccount,
      });
      return response.accessToken;
    } catch (e: any) {
      console.log("[AuthHook] usesubmit err", e);
      setError(e.message);
      history.push("/provider"); // Redirect to provider route in case of error
      throw e;
    }
  };

  const submitApiCall = async (path: string, payload: any) => {
    const token = await getToken();
    if (!token) {
      throw new Error("[AuthHook] Token acquisition failed");
    }

    try {
      const config = Configuration(token);
      const response = await axios.post(
        apiRequest + "/" + path,
        payload,
        config
      );
      return response;
    } catch (err) {
      console.log("[AuthHook] axios err", err);
      throw err;
    }
  };

  return { submitApiCall, error };
};

export { useSubmitApi };
