import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import plusCircle from "assets/PlusCircle.svg";
import minusCircle from "assets/MinusCircle.svg";
import "./ClientFAQ.scss";

export default function PatientFaqsection() {
  const [isExpanded1, setIsExpanded1] = useState<boolean>(false);
  const [isExpanded2, setIsExpanded2] = useState<boolean>(false);

  const handleAccordionChange1 = () => {
    setIsExpanded1((prevExpanded) => !prevExpanded); // Toggle the state for Accordion 1
  };

  const handleAccordionChange2 = () => {
    setIsExpanded2((prevExpanded) => !prevExpanded); // Toggle the state for Accordion 2
  };

  return (
    <>
      <div className="Accordian-main">
        <span className="global-content-header">FAQ</span>
        <Accordion
          className="Accordian-faq"
          expanded={isExpanded1}
          onChange={handleAccordionChange1}
          key="accordion1" // Unique key prop for Accordion 1
        >
          <AccordionSummary
            expandIcon={
              <img src={isExpanded1 ? minusCircle : plusCircle} alt="Expand" />
            }
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography className="section-heading">
              How to reschedule or cancel an appointment
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ol>
              <li>
                {" "}
                <Typography>
                  Please review the cancellation policy below.
                </Typography>
              </li>
              <li>
                <Typography>
                  You may reschedule/cancel an appointment byreaching out to us
                  using the contact form below or calling{" "}
                  <a href="tel:888-885-5068">888-885-5068</a> {", "} press
                  option 2.
                </Typography>
              </li>
            </ol>
          </AccordionDetails>
        </Accordion>
      </div>
      {!isExpanded1 && (
        <style>{`.Accordian-main .MuiAccordionSummary-root { border: none; }`}</style>
      )}
      {!isExpanded2 && (
        <style>{`.Accordian-main2 .MuiAccordionSummary-root { border: none; }`}</style>
      )}

      <div className=" Accordian-main2">
        <Accordion
          className="Accordian-faq"
          expanded={isExpanded2}
          onChange={handleAccordionChange2}
          key="accordion2" // Unique key prop for Accordion 2
        >
          <AccordionSummary
            expandIcon={
              <img src={isExpanded2 ? minusCircle : plusCircle} alt="Expand" />
            }
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="section-heading">
              Cancellation Policy
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              At Cortica, we believe consistent treatment is critical for your
              child's success. Our cancellation policy ensures fair access to
              our services and allows us to offer sessions to families waiting
              for them when cancellations occur.
            </Typography>
            <ol>
              <li>
                <Typography>
                  To ensure that other families can use the canceled sessions,
                  please cancel your appointments with at least three days (72
                  hours) of notice.
                </Typography>
              </li>
              <li>
                <Typography>
                  If you contact us to cancel an appointment with less than
                  three days notice, you will be charged a $50 cancellation fee.
                </Typography>
              </li>
              <li>
                <Typography>
                  You will be charged a $100 cancellation fee if you fail to
                  attend a scheduled appointment.
                </Typography>
              </li>
              <li>
                <Typography>
                  Two consecutive no-shows may result in removal from the
                  regular schedule.
                </Typography>
              </li>

              <li>
                <Typography>
                  If you arrive more than ten minutes late for an appointment,
                  your session may be canceled and you will be charged a $50
                  service fee. Sessions that start late will end at the usually
                  scheduled time.
                </Typography>
              </li>
            </ol>
            <Typography>
              Unfortunately, these charges cannot be billed to insurance, and we
              will charge the cancellation fee to the credit card on file for
              your account. Our team will offer to reschedule your child's
              sessions as schedules allow.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}
