import React, { useEffect, useState } from "react";
import {
  ADMIN_APPOINTMENTS_ARRAY,
  DRIVETIME_APPT_IDS,
} from "constant/BiPortalConstants";
import { CustomAppointmentWrapper } from "./style";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  adjustDateHours,
  initValues,
  mapToValuedOption,
} from "./appointments.helper";
import { APPOINTMENT_ADD_SCHEMA } from "../../../../utils/BIPortal/Validators/appointmets";
import { FormItem } from "../../../../sharedComponents/reactHookComponents/formItem";

import {
  RCDatepicker,
  Select,
  TimerTextField,
} from "sharedComponents/reactHookComponents";
import moment from "moment";
import {
  AddressInterface,
  DropdownOption,
  eventAddressData,
} from "model/appointments";
import ConfirmAppointment from "./confirmAppointment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import SpinnerV2 from "../../spinnerV2/spinnerV2";
import { ApiCalls } from "./AllAppointmentsApi";
import Popup from "sharedComponents/reactHookComponents/popup/popup";
import DriveTimeMileage from "./DriveTimeMileage";
import { useWebSocket } from "utils/WebSockets/WebSocketProvider";
import CrBannerError from "sharedComponents/Error/crBannerError";
import PtoConflictErrors from "sharedComponents/Error/ptoConflictErrors";
import { TextAreaField } from "sharedComponents/reactHookComponents/textfield";
import { useSubmitApi } from "customHooks/authHook";

interface Props {
  providerId: number;
  clinicId: number;
  providerName: string;
  providerEmail: string;
  prevDate?: string;
}

const NewAppointmentForm: React.FC<Props> = ({
  providerId,
  clinicId,
  providerName,
  providerEmail,
  prevDate,
}) => {
  const history = useHistory();
  const { webSocketData } = useWebSocket();
  const { submitApiCall } = useSubmitApi();

  const [displayBanner, setDisplayBanner] = useState<boolean>(false);

  useEffect(() => {
    if (webSocketData?.athena || webSocketData?.cr) {
      if (webSocketData?.athena !== true || webSocketData?.cr !== true) {
        setDisplayBanner(true);
      } else {
        setDisplayBanner(false);
      }
    } else {
      setDisplayBanner(false);
    }
  }, [webSocketData]);

  const appointmentsList = ADMIN_APPOINTMENTS_ARRAY;

  const apptList: DropdownOption[] = mapToValuedOption(appointmentsList);

  const [isFormVisible, setVisible] = useState(true);
  const [timeValidate, setTimeValidate] = React.useState<boolean>(false);
  const [isBeforeStartTime, setIsBeforeStartTime] =
    React.useState<boolean>(false);

  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [isDayConfirmed, setIsConfirmed] = React.useState<boolean>();
  const [isDayNotAvailable, setDayNotAvailable] = React.useState<boolean>();
  const [showAddressFields, setShowAddressFields] =
    React.useState<boolean>(false);

  const [headerTitle, setHeaderTitle] = useState<string>("Add New Appointment");

  const [addressLoader, initiateAddressLoader] = useState<boolean>(false);
  const [ptoConflictCheck, initiatePtoConflictCheck] = useState<boolean>(false);
  const [hasConflictingPTO, setHasConflictingPTO] = useState<boolean>(false);
  const [ptoStartDate, setPtoStartDate] = useState<boolean>();
  const [ptoEndDate, setPtoEndDate] = useState<boolean>();
  const [softloading, setsoftloading] = useState(false);
  const [backendError, setBackendError] = React.useState<boolean>(false);

  const [mileAgeCalculation, setMileAgeCalculation] =
    React.useState<boolean>(false);

  const [fetchedEventAddress, setFetchedStartingAddress] = useState<
    eventAddressData | undefined
  >();
  const [fullStartingAddress, setFullStartingAddress] = useState<
    AddressInterface | undefined
  >({
    addressLine1: "",
    city: "",
    state: "",
    freeformAddress: "",
    postalCode: "",
    lat: "",
    lon: "",
  });
  const [fullEndingAddress, setFullEndingAddress] = useState<
    AddressInterface | undefined
  >({
    addressLine1: "",
    city: "",
    state: "",
    freeformAddress: "",
    postalCode: "",
    lat: "",
    lon: "",
  });

  const [fetchingStartAddress, setFetchingStartAddress] =
    useState<boolean>(false);
  const [fetchingEndAddress, setFetchingEndAddress] = useState<boolean>(false);

  const [mileError, setMileError] = useState<boolean>(false);

  const [startingAddressSuggestions, setStartingAddressSuggestions] = useState<
    any[]
  >([]);
  const [destinationAddressSuggestions, setDestinationAddressSuggestions] =
    useState<any[]>([]);

  const [isFirstLastDrive, setIsFirstLastDrive] = useState<boolean | null>(
    null
  );
  const [isExceedingMaxMiles, setIsExceedingMaxMiles] =
    useState<boolean>(false);

  const methods = useForm<any>({
    defaultValues: initValues(apptList),
    resolver: yupResolver(APPOINTMENT_ADD_SCHEMA),
    mode: "all",
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: "onChange",
  });

  const control = methods.control;

  const startDate = useWatch({
    control: methods.control,
    name: ["startDate"],
  });

  const endDate = useWatch({
    control: methods.control,
    name: ["endDate"],
  });

  const selectedDay = useWatch({
    control: methods.control,
    name: ["selectedDay"],
  });

  const appointmentType = useWatch({
    control: methods.control,
    name: ["appointmentType"],
  });

  useEffect(() => {
    if (isFormVisible) {
      setShowConfirm(false);
    } else {
      setShowConfirm(true);
    }
  }, [isFormVisible]);

  useEffect(() => {
    let payloadDate;

    if (selectedDay && selectedDay.length > 0 && selectedDay[0] !== null) {
      payloadDate = moment(new Date(selectedDay[0]))
        .startOf("day")
        .toISOString();

      const payload = {
        providerId: providerId,
        date: payloadDate,
      };
      checkDayStatus(payload);
    }
  }, [selectedDay]);

  const selectedAppointment = useWatch({
    control: methods.control,
    name: ["appointmentType"],
  });

  const startDateHandler = React.useCallback(
    (value: any) => {
      const date = moment(value.$d);
      const time = date;
      methods.setValue("startDate", time);
      methods.trigger(["startDate"]);
      const endDate = methods.getValues("endDate");
      if (endDate) {
        const momentStartValue = moment(time, "HH:mm");
        const momentEndValue = moment(endDate, "HH:mm");
        if (
          momentEndValue.isBefore(momentStartValue) ||
          momentEndValue.isSame(momentStartValue)
        ) {
          setIsBeforeStartTime(true);
          setTimeValidate(true);
        } else {
          setTimeValidate(false);
          setIsBeforeStartTime(false);
        }
        methods.trigger("endDate");
      } else {
      }
    },
    [methods]
  );

  const appointmentHandler = React.useCallback(
    (val: number) => {
      methods.setValue("appointmentType", val);
    },
    [methods]
  );

  const endDateHandler = React.useCallback(
    (value: any) => {
      const date = moment(value.$d);
      const time = date;
      methods.setValue("endDate", time);
      const startDate = methods.getValues("startDate");
      if (startDate) {
        const momentStartValue = moment(startDate, "HH:mm");
        const momentEndValue = moment(time, "HH:mm");
        if (
          momentEndValue.isBefore(momentStartValue) ||
          momentEndValue.isSame(momentStartValue)
        ) {
          setIsBeforeStartTime(true);
          setTimeValidate(true);
        } else {
          setTimeValidate(false);
          setIsBeforeStartTime(false);
        }
        methods.trigger("startDate");
      }
      methods.trigger("endDate");
    },
    [methods]
  );

  const startingAddressHandler = React.useCallback(
    (value: any) => {
      methods.setValue("startingAddress", value.freeformAddress);
      // const startingAddress = methods.getValues("startingAddress");
      methods.trigger("startingAddress");
      setFullStartingAddress(value);
    },
    [methods]
  );

  const destinationAddressHandler = React.useCallback(
    (value: any) => {
      methods.setValue("destinationAddress", value.freeformAddress);

      methods.trigger("destinationAddress");
      setFullEndingAddress(value);
    },
    [methods]
  );

  const milesHandler = React.useCallback(
    (value: any) => {
      methods.setValue("miles", Number(value));

      methods.trigger("miles");
    },
    [methods]
  );

  const driveTimeHandle = React.useCallback(
    (val: any) => {
      methods.setValue("isFirstLastDrive", val);
      setIsFirstLastDrive(val);
    },
    [methods]
  );

  const reimburseableMilesHandler = React.useCallback(
    (value: any) => {
      methods.setValue("reimburseableMiles", Number(value));

      methods.trigger("reimburseableMiles");
    },
    [methods]
  );

  const handleBackClick = () => {
    if (isFormVisible) {
      history.push({
        pathname: "/providerappointments",
        state: {
          prefStartDate: prevDate,
        },
      });
    } else {
      setHeaderTitle("Add Appointment");
      setVisible(true);
    }
  };

  async function checkDayStatus(payload: any) {
    setBackendError(false);
    setsoftloading(true);

    try {
      const response = await submitApiCall(
        ApiCalls.ConfirmDayStatusCall,
        payload
      );
      setIsConfirmed(response.data.isConverted);
      response.data.isConverted
        ? setDayNotAvailable(true)
        : setDayNotAvailable(false);
      setsoftloading(false);
    } catch (error) {
      console.log("[DayConfirmationCheck][checkDayStatus] error", error);
      setBackendError(true);
    }
  }

  //check confirm code

  const submitForm = React.useCallback(() => {
    setVisible(false);
    setHeaderTitle("Confirm New Appointment");
  }, []);

  const submitError = React.useCallback(() => {
    methods.trigger();
  }, [methods]);

  const handleClosePopUp = () => {
    setBackendError(false);
    history.push({
      pathname: "/providerappointments",
      state: {
        prefStartDate: prevDate,
      },
    });
  };

  const fetchEventAddresses = async () => {
    initiateAddressLoader(true);
    //TODO: @sushma @sravan need to get this at a common place

    try {
      const payload = {
        providerId: providerId,
        startTime: adjustDateHours(selectedDay[0], startDate[0]), // assuming startDate is an array
        endTime: adjustDateHours(selectedDay[0], endDate[0]), // assuming endDate is an array
        selectedDay: selectedDay[0], // assuming selectedDay is an array
      };
      const response = await submitApiCall(
        ApiCalls.fetchaxoneventaddresses,
        payload
      );

      const { startingAddress, destinationAddress } = response.data;

      setFetchedStartingAddress({
        startingAddress: startingAddress,
        endingAddress: destinationAddress,
      });

      initiateAddressLoader(false);

      setShowAddressFields(true);
    } catch (error) {
      console.error(
        "[AddNewAppt] [DriveTime] Error fetching event addresses",
        error
      );

      initiateAddressLoader(false);
      setMileError(true);
    }
  };

  const checkPtoConflicts = async () => {
    initiatePtoConflictCheck(true);
    try {
      const payload = {
        providerId: providerId,
        startTime: adjustDateHours(selectedDay[0], startDate[0]),
        endTime: adjustDateHours(selectedDay[0], endDate[0]),
      };
      const response = await submitApiCall(ApiCalls.checkptoconflicts, payload);

      setTimeout(() => {
        setHasConflictingPTO(response.data.hasConflicts);
      }, 0);
      setTimeout(() => {
        setPtoStartDate(response.data.ptoStartDate);
      }, 0);
      setTimeout(() => {
        setPtoEndDate(response.data.ptoEndDate);
      }, 0);

      initiatePtoConflictCheck(false);
    } catch (error) {
      initiatePtoConflictCheck(false);
    }
  };

  const fetchAddressSuggestions = async (query: string): Promise<any[]> => {
    try {
      const payload = {
        query: query,
      };
      const response = await submitApiCall(
        ApiCalls.fetchaddresssuggestions,
        payload
      );

      const suggestions = response.data;
      return suggestions;
    } catch (error: any) {
      console.error(
        "[AddNewAppt] [fetchAddressSuggestions]Error fetching suggestions:",
        error
      );
      return [];
    }
  };

  const resetDriveTimeMap = () => {
    setShowAddressFields(false);
    setFetchedStartingAddress(undefined);
    setFullStartingAddress({
      addressLine1: "",
      city: "",
      state: "",
      freeformAddress: "",
      postalCode: "",
      lat: "",
      lon: "",
    });
    setFullEndingAddress({
      addressLine1: "",
      city: "",
      state: "",
      freeformAddress: "",
      postalCode: "",
      lat: "",
      lon: "",
    });
    setFetchingStartAddress(false);
    setFetchingEndAddress(false);
    setStartingAddressSuggestions([]);
    setDestinationAddressSuggestions([]);
    resetMethodAddresses();
  };

  const resetMethodAddresses = () => {
    methods.setValue("startingAddress", null);
    methods.setValue("destinationAddress", null);
    methods.setValue("miles", null);
    methods.setValue("reimburseableMiles", null);
    methods.setValue("isLastDrive", false);
    methods.setValue("notes", null);
  };

  useEffect(() => {
    if (fetchedEventAddress?.startingAddress) {
      setFetchingStartAddress(true);
      fetchAddressSuggestions(fetchedEventAddress?.startingAddress).then(
        (suggestions) => {
          setStartingAddressSuggestions(suggestions);
          if (suggestions?.length) {
            setFullStartingAddress(suggestions[0]);
            methods.setValue("startingAddress", suggestions[0].freeformAddress);
            setFetchingStartAddress(false);
          }
        }
      );
    }
    if (fetchedEventAddress?.endingAddress) {
      setFetchingEndAddress(true);
      fetchAddressSuggestions(fetchedEventAddress?.endingAddress).then(
        (suggestions) => {
          setDestinationAddressSuggestions(suggestions);
          if (suggestions?.length) {
            setFullEndingAddress(suggestions[0]);
            methods.setValue(
              "destinationAddress",
              suggestions[0].freeformAddress
            );
            setFetchingEndAddress(false);
          }
        }
      );
    }
  }, [
    fetchedEventAddress?.startingAddress,
    fetchedEventAddress?.endingAddress,
  ]);

  useEffect(() => {
    resetDriveTimeMap();
  }, [startDate, endDate, selectedDay, appointmentType]);

  useEffect(() => {
    setHasConflictingPTO(false);
    const fetchData = async () => {
      if (
        startDate[0] !== undefined &&
        endDate[0] !== undefined &&
        selectedDay !== undefined &&
        appointmentType !== undefined
      ) {
        if (!isBeforeStartTime) {
          await checkPtoConflicts();
        }
      }
      await new Promise((resolve) => setTimeout(resolve, 500));

      console.log("[NewAppointmentform dr id]", selectedAppointment[0]);

      if (
        //TODO: @sushma @sravan don't hardcode
        DRIVETIME_APPT_IDS.includes(selectedAppointment[0]) &&
        !isBeforeStartTime &&
        !isDayConfirmed &&
        startDate[0] !== undefined &&
        endDate[0] !== undefined
      ) {
        await fetchEventAddresses();
      } else {
        setShowAddressFields(false);
      }
    };

    const timer = setTimeout(fetchData, 500);

    return () => clearTimeout(timer);
  }, [isDayConfirmed, selectedAppointment, selectedDay, startDate, endDate]);
  return (
    <CustomAppointmentWrapper>
      {backendError && (
        <>
          <Popup
            isOpen={backendError}
            onClose={handleClosePopUp}
            heading={"Couldn't Check the Day Conversion Status"}
            paragraph={
              "We cannot check the day conversion status at this moment. Please try adding appointment after a few minutes. If the problem persists, Please contact IT support team"
            }
          />
        </>
      )}
      <div className="w-100 d-flex h-center">
        <div className="header-div">
          <div className="back-to h-end v-center">
            <button onClick={handleBackClick}>
              <FontAwesomeIcon className="icon-back" icon={faChevronLeft} />
            </button>
          </div>
          <div className="page-desc h-center v-center">
            <span>{headerTitle}</span>
          </div>
          <div className="back-to"></div>
        </div>
      </div>
      {displayBanner && (
        <div>
          <CrBannerError></CrBannerError>
        </div>
      )}
      <FormProvider {...methods}>
        <div className={isFormVisible ? "form-enclosure" : "d-none"}>
          <>
            <div
              className={
                softloading
                  ? "loading-io w-100 d-flex fx-column gutter-top-50"
                  : "d-none"
              }
            >
              <div className="load-div w-100 d-flex h-center">
                <SpinnerV2 />
              </div>
              <div className="title-div h-center gutter-top-20">
                <span>Checking Day Status</span>
              </div>
            </div>
            <div className={!softloading ? "form-enclosure" : "d-none"}>
              <div className="main-form">
                <div className="form-row">
                  <div className="form-row-item">
                    <FormItem optional={false} label="Date">
                      <RCDatepicker
                        placeholder="MM/DD/YYYY"
                        control={control}
                        name="selectedDay"
                        format="MM/DD/YYYY"
                        inputReadOnly
                      />
                    </FormItem>
                  </div>
                  <div className="form-row-item">
                    <FormItem optional={false} label="Appointment Type">
                      <Controller
                        name="appointmentType"
                        control={methods.control}
                        render={({ field }) => (
                          <>
                            <Select
                              name="appointmentType"
                              placeholder="Select Appointment Type"
                              options={apptList}
                              field={field}
                              value={selectedAppointment}
                              errors={
                                methods?.formState?.errors?.appointmentType
                              }
                              onChange={appointmentHandler}
                            />
                          </>
                        )}
                      />
                    </FormItem>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-row-item">
                    <FormItem optional={false} label="Start Time">
                      <Controller
                        name="startDate"
                        control={methods.control}
                        render={({ field }) => (
                          <TimerTextField
                            className={"time-style"}
                            field={field}
                            errors={methods?.formState?.errors?.startDate}
                            value={startDate}
                            onChange={(newValue: any) => {
                              startDateHandler(newValue);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 900,
                            }}
                          />
                        )}
                      />
                    </FormItem>
                  </div>
                  <div className="form-row-item">
                    <FormItem optional={false} label="End Time">
                      <Controller
                        name="endDate"
                        control={methods.control}
                        render={({ field }) => (
                          <TimerTextField
                            className={"time-style"}
                            field={field}
                            errors={methods?.formState?.errors?.endDate}
                            value={endDate}
                            onChange={(newValue: any) => {
                              endDateHandler(newValue);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 900,
                            }}
                          />
                        )}
                      />
                    </FormItem>
                  </div>
                </div>
                <div className="error-time-wrapper">
                  {hasConflictingPTO && (
                    <PtoConflictErrors
                      ptoStartDate={ptoStartDate}
                      ptoEndDate={ptoEndDate}
                    />
                  )}
                  {timeValidate && (
                    <>
                      <span style={{ color: "#cc3f3f" }}>
                        {isBeforeStartTime ? (
                          <>The start time must be earlier than the end time.</>
                        ) : (
                          <>ABA appointments must be at least 5 minutes.</>
                        )}
                      </span>
                    </>
                  )}
                  {isDayNotAvailable && (
                    <>
                      <span style={{ color: "#cc3f3f" }}>
                        The Selected date has been confirmed. Please select
                        another day to create appointment.
                      </span>
                    </>
                  )}

                  {addressLoader && !hasConflictingPTO && (
                    <div className="loading-io w-100 d-flex fx-column gutter-top-50">
                      <div className="load-div w-100 d-flex h-center">
                        <SpinnerV2 />
                      </div>
                      <div className="title-div h-center gutter-top-20">
                        <span>Loading drive time data</span>
                      </div>
                    </div>
                  )}
                  {ptoConflictCheck && (
                    <div className="loading-io w-100 d-flex fx-column gutter-top-50">
                      <div className="load-div w-100 d-flex h-center">
                        <SpinnerV2 />
                      </div>
                      <div className="title-div h-center gutter-top-20">
                        <span>Checking for PTO Conflicts</span>
                      </div>
                    </div>
                  )}
                </div>

                {showAddressFields && !addressLoader && !hasConflictingPTO && (
                  <DriveTimeMileage
                    // fetchedEventAddress={fetchedEventAddress}
                    initiateAddressLoader={initiateAddressLoader}
                    fetchAddressSuggestions={fetchAddressSuggestions}
                    startingAddressHandler={startingAddressHandler}
                    destinationAddressHandler={destinationAddressHandler}
                    milesHandler={milesHandler}
                    // muiSwitchHandler={muiSwitchHandler}
                    driveTimeHandle={driveTimeHandle}
                    reimburseableMilesHandler={reimburseableMilesHandler}
                    // isFirstOrLastDrive={isFirstLastDriveProp}
                    setIsExceedingMaxMiles={setIsExceedingMaxMiles}
                    isFirstLastDrive={isFirstLastDrive}
                    fetchingStartAddress={fetchingStartAddress}
                    fetchingEndAddress={fetchingEndAddress}
                    startingAddressSuggestions={startingAddressSuggestions}
                    setStartingAddressSuggestions={
                      setStartingAddressSuggestions
                    }
                    destinationAddressSuggestions={
                      destinationAddressSuggestions
                    }
                    setDestinationAddressSuggestions={
                      setDestinationAddressSuggestions
                    }
                    fullStartingAddress={fullStartingAddress}
                    fullEndingAddress={fullEndingAddress}
                    setMileAgeCalculation={setMileAgeCalculation}
                    errors={{
                      startingAddress: Array.isArray(
                        methods?.formState?.errors?.startingAddress
                      )
                        ? methods?.formState?.errors?.startingAddress[0]
                        : methods?.formState?.errors?.startingAddress,
                      destinationAddress: Array.isArray(
                        methods?.formState?.errors?.destinationAddress
                      )
                        ? methods?.formState?.errors?.destinationAddress[0]
                        : methods?.formState?.errors?.destinationAddress,
                      isFirstLastDrive: Array.isArray(
                        methods?.formState?.errors?.isFirstLastDrive
                      )
                        ? methods?.formState?.errors?.isFirstLastDrive[0]
                        : methods?.formState?.errors?.isFirstLastDrive,
                    }}
                  />
                )}
                {mileError && (
                  <div className="error-block">
                    <p>
                      Something went wrong while fetching the address or
                      calculating miles. Please start over the process
                    </p>
                  </div>
                )}

                <div className="form-row ant-col-100 txt-area">
                  <FormItem optional={false} label="Note (Optional)">
                    <Controller
                      name="note" // Update controller name to "note"
                      control={methods.control}
                      render={({ field }) => (
                        <TextAreaField // Replace DesktopTimePicker with TextAreaField
                          field={field}
                          errors={methods?.formState?.errors?.note}
                          rows={4} // Optionally specify rows for the text area
                          placeholder="Add appointment notes here :)"
                        />
                      )}
                    />
                  </FormItem>
                </div>

                <div className="submit-button">
                  <button
                    disabled={
                      isBeforeStartTime ||
                      isDayConfirmed ||
                      hasConflictingPTO ||
                      ptoConflictCheck ||
                      mileAgeCalculation ||
                      isExceedingMaxMiles
                    }
                    type="submit"
                    onClick={methods.handleSubmit(submitForm, submitError)}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </>
        </div>

        {showConfirm && (
          <ConfirmAppointment
            providerId={providerId}
            providerClinicId={clinicId}
            providerName={providerName}
            providerEmail={providerEmail}
            startingAddress={fullStartingAddress}
            destinationAddress={fullEndingAddress}
          ></ConfirmAppointment>
        )}
      </FormProvider>
    </CustomAppointmentWrapper>
  );
};

export default React.memo(NewAppointmentForm);
