import { AwsRum } from 'aws-rum-web';

try {
  const config = {
    sessionSampleRate: 1,
    endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
    identityPoolId: process.env.REACT_APP_RUM_IDENTITY_POOL_ID,
    telemetries: ["errors","performance","http"],
    allowCookies: true,
    enableXRay: true
  };

  const APPLICATION_ID = process.env.REACT_APP_RUM_APPLICATION_ID;
  const APPLICATION_VERSION = '1.0.0';
  const APPLICATION_REGION = 'us-east-1';

  const awsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}