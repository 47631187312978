import React, { Dispatch, SetStateAction } from "react";

import "./uxstyle.scss";

interface IProps {
  setPollCount: Dispatch<SetStateAction<number>>;
  setBoolean: Dispatch<SetStateAction<boolean>>;
}

const Message: React.FC<IProps> = ({ setBoolean, setPollCount }) => {
  const refresh = () => {
    setPollCount(0);
    setBoolean(false);
  };
  return (
    <>
      <div className="whole-wrapper">
        <div className="content-div">
          <div className="message-div">
            <p>
              Looks like the application has been idle for some time. You can
              refresh to continue using the application
            </p>
          </div>
          <div className="add-new">
            <button onClick={refresh}>Refresh</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Message);
