import React, { createContext, useContext, useState } from "react";

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const [webSocketData, setWebSocketData] = useState(null);

  const updateWebSocketData = (newData) => {
    setWebSocketData(newData);
  };

  return (
    <WebSocketContext.Provider value={{ webSocketData, updateWebSocketData }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};