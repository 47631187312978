import React from "react";
import loading from "assets/loading.svg";
import "./style.sass";

const Loader = () => (
  <div className="spinner">
    <img src={loading} alt="Loading" />
  </div>
);

export default Loader;
