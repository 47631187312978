import React from "react";
import { LibSelect, LibOption as Option } from "library/ui/select/select.style";
import { Empty } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { FormError } from "./error";

const Select = ({ field, errors, options, ...restProps }) => {
  return (
    <>
      <LibSelect
        suffixIcon={restProps.suffixIcon || <CaretDownOutlined />}
        getPopupContainer={(trigger) => trigger.parentNode}
        notFoundContent={<SelectOptionsNotFound list={options} />}
        filterOption={(input, option) => {
          return (
            option.key
              .toLowerCase()
              .trim()
              .indexOf(input.toLowerCase().trim()) >= 0
          );
        }}
        {...restProps}
        {...field}
      >
        {options &&
          options.map(({ value, label, disabled }) => (
            <Option key={`${label}${value}`} value={value} disabled={disabled}>
              {label}
            </Option>
          ))}
      </LibSelect>
      {field?.name && <FormError name={field.name} errors={errors} />}
    </>
  );
};

const SelectOptionsNotFound = React.memo(({ list }) => {
  return Array.isArray(list) && list.length === 0 ? (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  ) : (
    <div>Loading...</div>
  );
});

export default React.memo(Select);
