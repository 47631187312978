import React from "react";
import { FormItemWrap } from "./formItem.styled";
import { Col } from "antd";
import * as PropTypes from "prop-types";

const FormItem = ({ label, children, optional, ...otherProps }) => {
  return (
    <Col {...otherProps}>
      <FormItemWrap>
        <div className="top-wrap">
          {label && <label>{label}</label>}
          {optional && <span className="optional">optional</span>}
        </div>
        {children}
      </FormItemWrap>
    </Col>
  );
};

FormItem.propTypes = {
  label: PropTypes.string,
  optional: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

FormItem.defaultProps = {
  label: null,
  optional: null,
  children: null,
};

export default FormItem;
export { FormItem };
