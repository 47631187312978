import React from "react";
import Loading from "assets/loading.svg";
import "./Spinner.sass";

const Spinner = () => {
  return (
    <div className="loader-wrapper">
      <div className="loader">
        <img className="spin" src={Loading} alt="spinner" />
      </div>
    </div>
  );
};

export default Spinner;
