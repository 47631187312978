import React, { useState, useEffect, useRef } from "react";
import "./userMenu.sass";

const UserMenu = ({ email, name, logoutAction }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef();

  const toggle = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (menuOpen && menuRef.current && !menuRef.current.contains(e.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [menuOpen]);

  return (
    <div ref={menuRef}>
      {menuOpen && (
        <div className="sign-out">
          <div className="user-info">
            <span>{email}</span>
          </div>
          <span onClick={logoutAction}>Log Out</span>
        </div>
      )}
      <div className="toggle-wrapper" onClick={toggle}>
        <div className="username">
          <p>{name.charAt(0).toUpperCase()}</p>
        </div>
      </div>
    </div>
  );
};
export default UserMenu;
