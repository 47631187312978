import { Typography } from "@mui/material";
import React from "react";
import "./errorMessage.css";

const CrBannerError: React.FC = () => {
  return (
    <div className="error-message-container">
      <div className="error-message">
        <Typography>
          <p className="error-message-1">
            CentralReach is experiencing slowness and your changes might fail to
            sync. Please do not make changes to your schedule until this banner
            is removed from your home page
          </p>
        </Typography>
      </div>
    </div>
  );
};

export default React.memo(CrBannerError);
