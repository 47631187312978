import React from "react";
import "./subHeader.sass";

interface Iprops {
  client?: string;
  isLoading: boolean;
}

const SubHeader: React.FC<Iprops> = ({}) => {
  return (
    <>
      <div className="subheader-container">
        <div className="subheader">
          <span className="global-content-wish-text">Hello!</span>
        </div>
      </div>
    </>
  );
};

export default SubHeader;
