import React from "react";
import "./footer.sass";

const Footer = () => {
  const year: number = new Date().getFullYear();
  return (
    <>
      <footer>
        <div className="left-side">
          <p> Copyright &copy; {year} Cortica</p>
        </div>
        <div className="right-side">
          <p>
            All Rights Reserved |{" "}
            <a
              href="https://www.corticacare.com/terms-use"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms &amp; Conditions
            </a>
            |
            <a
              href="https://www.corticacare.com/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Privacy Policy
            </a>
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
