import React from "react";
import { Redirect } from "react-router-dom";
import Loader from "../Loader/Loader";
import { useAuth0 } from "../../react-auth0-spa";
import { useIsAuthenticated as useMsalIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";

const Main = () => {
  const {
    loading: auth0Loading,
    isAuthenticated: isAuth0Authenticated,
    logout: logout,
    user: user,
  } = useAuth0();
  const { instance } = useMsal();
  const msalIsAuthenticated = useMsalIsAuthenticated();
  const activeAccount = instance.getActiveAccount();

  const MsAuthenticated = msalIsAuthenticated && activeAccount;

  if (auth0Loading) return <Loader />;

  if (
    isAuth0Authenticated &&
    user["https://corticacare.com/app_metadata"].provider
  ) {
    console.log("identified as provider auth0. Hence logging out");
    logout();
  } else if (MsAuthenticated) {
    console.log("[MAIN.JS MS AUTH] 1");
    return <Redirect to="/providerappointments" />;
  } else if (isAuth0Authenticated) {
    console.log("[MAIN.JS MS AUTH] 2");
    return <Redirect to="/appointments" />;
  } else {
    console.log("[MAIN.JS MS AUTH] 3");
    return <Redirect to="/login" />;
  }
};

export default Main;
