import { PublicClientApplication, LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
    authority: process.env.REACT_APP_MSAL_LOGIN_URL,
    redirectUri: `${window.location.origin}/blank.html`,
    postLogoutRedirectUri: "/provider",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
    secureCookies: true,
  },
  system: {
    tokenRenewalOffsetSeconds: 5 * 60,
    loggerOptions: {
      logLevel: LogLevel.Warning,
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error({
              error: new Error(`Authentication Error :: ${message || ""}`),
            });
            return;
          case LogLevel.Info:
          case LogLevel.Verbose:
            return;
          case LogLevel.Warning:
            console.log({
              name: "Authentication Warning",
              properties: [`level: ${level}`, `message: ${message}`],
            });
            return;
          default:
            console.log({
              name: "Unknown Log Level",
              properties: [`level: ${level}`, `message: ${message}`],
            });
            return;
        }
      },
      piiLoggingEnabled: false,
    },
  },
};

export const loginRequest = {
  scopes: [`${process.env.REACT_APP_MSAL_CLIENT_ID}/.default`],
  authority: process.env.REACT_APP_MSAL_LOGIN_URL,
  prompt: "select_account"
};

export const msalInstance = new PublicClientApplication(msalConfig);
