import React from "react";
import PropTypes from "prop-types";
import "./popup.css";

const Popup = ({ isOpen, onClose, heading, paragraph, onSubmit }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="popup-overlay">
      <div className="popup">
        <h2 className="heading">{heading}</h2>
        <p className="paragraph">{paragraph}</p>
        {onSubmit && (
          <button className="pop-up-submit-button" onClick={onSubmit}>
            Submit Ticket
          </button>
        )}
        <div className="close-button">
          <button className="pop-up-close-button" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

Popup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  heading: PropTypes.string,
  paragraph: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default Popup;
