import React from "react";
import { withRouter } from "react-router-dom";
import { Auth0Context } from "react-auth0-spa";
import axios from "axios";
import Loader from "features/Loader/Loader";
import Logo from "assets/Color.svg";
import "./UnverifiedUser.sass";

class UnverifiedUser extends React.Component {
  state = {
    emailSent: false,
    error: false,
    loading: false,
  };

  getUserEmail = () => {
    const { user } = this.context;
    return user.email;
  };

  checkUserVerification() {
    const { user } = this.context;
    if (user && user.email_verified) this.navigateToMain();
    else if (!user) this.navigateToLogin();
  }

  resendVerificationEmail = async () => {
    this.setState({ loading: true });
    const { getTokenSilently } = this.context;
    const token = await getTokenSilently();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const url = `${process.env.REACT_APP_BACKEND_API}/resendVerificationEmail`;
    try {
      const response = await axios.post(url, null, config);
      if (response.status === 200) {
        this.setState({
          emailSent: true,
          loading: false,
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({
        loading: false,
      });
    }
  };

  navigateToMain = () => {
    this.props.history.push("/");
  };

  navigateToLogin = () => {
    this.props.history.push("/login");
  };

  handleLogout = () => {
    const { logout } = this.context;
    logout();
  };

  render() {
    this.checkUserVerification();

    if (this.state.loading) return <Loader />;

    return (
      <section className="verifyemail-wrapper">
        <div className="verifyemail">
          <div className="img-wrapper">
            <img src={Logo} alt="Cortica logo" />
          </div>
          <div className="text-wrapper">
            <p>Verify Your Email Address</p>
            <p>
              An email has been sent to {this.getUserEmail()}.
              <br />
              Please click on the link in your email to verify your email
              address.
            </p>
            <p>
              Didn’t get an email?
              <button onClick={this.resendVerificationEmail}> Resend</button>
            </p>
            <button onClick={this.handleLogout}>Back to Log In</button>
          </div>
        </div>
      </section>
    );
  }
}

UnverifiedUser.contextType = Auth0Context;

export default withRouter(UnverifiedUser);
