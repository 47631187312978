export const contactFormInitValues = {
  firstName: undefined,
  lastName: undefined,
  contactReason: undefined,
  subject: undefined,
  description: undefined,
};

export const contactReasons = [
  {
    value: "Cancel Appointment",
    label: "Cancel Appointment",
  },
  {
    value: "Reschedule Appointment",
    label: "Reschedule Appointment",
  },
  {
    value: "General Scheduling Assistance",
    label: "General Scheduling Assistance",
  },
];
