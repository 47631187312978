import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import "./ContactForm.scss";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { CONTACT_FORM_SCHEMA } from "../../../../utils/PatientPortal/Validators/AvailabilityValidation";
import { TextField } from "../../../../sharedComponents/reactHookComponents/simpleTextInput";
import { FormItem } from "../../../../sharedComponents/reactHookComponents/formItem";
import { Select } from "../../../../sharedComponents/reactHookComponents";
import {
  contactFormInitValues,
  contactReasons,
} from "../../../../constant/patientPortalConstants";
import { TextAreaField } from "../../../../sharedComponents/reactHookComponents/textfield";
import { useAuth0 } from "react-auth0-spa";
import { ApplicationStages } from "constant/AppConstants";
import axios from "axios";
import CorticaLoader from "../../../../sharedComponents/CorticaLoader/CorticaLoader";

interface IProps {
  email: string;
  name: string;
}

const ContactForm: React.FC<IProps> = ({}) => {
  const { user, getTokenSilently } = useAuth0();

  const [showForm, setForm] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const [subjectCount, setSubjectCount] = useState(0);
  const [descriptionCount, setDescriptionCount] = useState(0);

  const [subjectLimit, setSubjectLimit] = useState(false);
  const [descLimit, setDescLimit] = useState(false);
  const [loading, setloading] = React.useState<boolean>(false);

  const maxSubjectLength = 60;
  const maxDescriptionLength = 1024;

  const methods = useForm<any>({
    defaultValues: contactFormInitValues,
    resolver: yupResolver(CONTACT_FORM_SCHEMA),
    mode: "all",
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: "onChange",
  });

  const submitRequest = async () => {
    const requestFlightPayload = {
      firstName: methods.getValues("firstName"),
      lastName: methods.getValues("lastName"),
      email: user.email,
      contactReason: methods.getValues("contactReason"),
      subject: methods.getValues("subject"),
      description: methods.getValues("description"),
    };

    const flightUrl =
      process.env.REACT_APP_STAGE === ApplicationStages.local
        ? `http://localhost:8000/sendContactFormData`
        : `${process.env.REACT_APP_BACKEND_API}/sendContactFormData`;

    const accessToken = await getTokenSilently();
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    try {
      setloading(true);
      await axios.post(flightUrl, requestFlightPayload, config);
      setForm(false);
      setloading(false);
    } catch (error) {
      setError(true);
      setForm(true);
      setloading(false);
      console.log("[contactForm error]", error);
    }
  };

  const submitForm = React.useCallback(() => {
    submitRequest();
  }, []);

  const submitError = React.useCallback(() => {
    methods.trigger();
  }, [methods]);

  const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= maxSubjectLength) {
      setSubjectCount(value.length);
      methods.setValue("subject", value);
    }
    if (value.length === maxSubjectLength) {
      setSubjectLimit(true);
    } else {
      setSubjectLimit(false);
    }
  };

  const handleReset = () => {
    resetForm();
    setError(false);
    setForm(true);
  };

  const resetForm = () => {
    methods.reset(contactFormInitValues);
    setSubjectCount(0);
    setDescriptionCount(0);
    setSubjectLimit(false);
    setDescLimit(false);
  };

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    if (value.length <= maxDescriptionLength) {
      setDescriptionCount(value.length);
      methods.setValue("description", value);
    }
    if (value.length === maxDescriptionLength) {
      setDescLimit(true);
    } else {
      setDescLimit(false);
    }
  };

  return (
    <>
      {loading && <CorticaLoader message="Submitting request" />}
      <div className="contact-main-container">
        <span className="global-content-header">Contact Us</span>
        {showForm ? (
          <>
            {error && (
              <div className="information-text it-error">
                <span>
                  Message unable to send. Please try again in a few moments or
                  reach out to our Customer Support Team.
                </span>
              </div>
            )}

            <FormProvider {...methods}>
              <div className="form-container">
                <div className="fluid-container">
                  <div className="fluid-row">
                    <div className="form-row">
                      <FormItem optional={false} label="Your first name *">
                        <Controller
                          name="firstName"
                          control={methods.control}
                          render={({ field }) => (
                            <TextField
                              id="firstName"
                              field={field}
                              errors={methods?.formState?.errors?.firstName}
                              placeholder="First Name"
                            />
                          )}
                        />
                      </FormItem>
                    </div>
                  </div>

                  <div className="fluid-row">
                    <div className="form-row al-items-flx-end">
                      <FormItem optional={false} label="Your last name *">
                        <Controller
                          name="lastName"
                          control={methods.control}
                          render={({ field }) => (
                            <TextField
                              id="lastName"
                              field={field}
                              errors={methods?.formState?.errors?.lastName}
                              placeholder="Last Name"
                            />
                          )}
                        />
                      </FormItem>
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <FormItem optional={false} label="Select reason *">
                    <Controller
                      name="contactReason"
                      control={methods.control}
                      render={({ field }) => (
                        <Select
                          id="contactReason"
                          name="contactReason"
                          placeholder="Select reason"
                          options={contactReasons}
                          field={field}
                          errors={methods?.formState?.errors?.contactReason}
                        />
                      )}
                    />
                  </FormItem>
                </div>

                <div className="form-row">
                  <FormItem optional={false} label="Subject *">
                    <Controller
                      name="subject"
                      control={methods.control}
                      render={({ field }) => (
                        <TextField
                          id="subject"
                          field={{ ...field, onChange: handleSubjectChange }}
                          errors={methods?.formState?.errors?.subject}
                          maxLength={maxSubjectLength}
                          placeholder="Input a brief summary of the issue"
                        />
                      )}
                    />
                    <span
                      className={`counter-text ${
                        subjectLimit ? "limit-exceeded" : ""
                      }`}
                    >
                      {subjectCount}/{maxSubjectLength}{" "}
                      {subjectLimit && "Subject length cannot exceed anymore"}
                    </span>
                  </FormItem>
                </div>

                <div className="form-row">
                  <FormItem
                    optional={false}
                    label="Describe what you need help with *"
                  >
                    <Controller
                      name="description"
                      control={methods?.control}
                      render={({ field }) => (
                        <TextAreaField
                          id="description"
                          field={{
                            ...field,
                            onChange: handleDescriptionChange,
                          }}
                          errors={methods?.formState?.errors?.description}
                          rows={4}
                          maxLength={maxDescriptionLength}
                          placeholder="Type your concern here :)"
                        />
                      )}
                    />
                    <span
                      style={{ color: descLimit ? "#FF5733" : "#1d1d1f" }}
                      className="counter-text"
                    >
                      {descriptionCount}/{maxDescriptionLength}{" "}
                      {descLimit && "Description length cannot exceed anymore"}
                    </span>
                  </FormItem>
                </div>

                <div className="submit-button">
                  <button
                    className="submit-btn-form"
                    type="submit"
                    onClick={methods.handleSubmit(submitForm, submitError)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </FormProvider>
          </>
        ) : (
          <>
            <div className="completed-submission">
              <div className="information-text">
                {!error && (
                  <span>
                    Thanks for reaching out! Your message has been submitted and
                    a team member will be in contact with you shortly. To submit
                    another request, please click the button below.
                  </span>
                )}
              </div>
              <div>
                <button className="submit-btn-form" onClick={handleReset}>
                  Submit another request
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(ContactForm);
