import { LeadEvent, ObserverAppointment } from "model/appointments";
import { FormItem } from "../../../../sharedComponents/reactHookComponents/formItem";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { EditAppointmentWrapper } from "./style";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ConfirmEdit from "./ConfirmEdit";
import { useWebSocket } from "utils/WebSockets/WebSocketProvider";
import CrBannerError from "sharedComponents/Error/crBannerError";
import { useForm, Controller, FormProvider, useWatch } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  compareTimes,
  getObserverAppointmentInitValues,
} from "./appointments.helper";
import { TimerTextField } from "sharedComponents/reactHookComponents";
import { OBSERVER_APPOINTMENT_SCHEMA } from "../../../../utils/BIPortal/Validators/appointmets";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";

interface LocationState {
  providerId: number;
  appointment: ObserverAppointment;
  leadEvent: LeadEvent;
  activeDay?: string;
}

const ObserverEditForm: React.FC = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { webSocketData } = useWebSocket();

  const leadEvent = location.state.leadEvent;
  const event = location.state.appointment;
  const activeDay = location.state.activeDay;

  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [timeValidate, setTimeValidate] = React.useState<boolean>(false);
  const [isBeforeStartTime, setIsBeforeStartTime] =
    React.useState<boolean>(false);
  const [isTimePrestine, setTimePrestine] = React.useState<boolean>(false);
  const [displayBanner, setDisplayBanner] = useState<boolean>(false);
  const [isFormVisible, setVisible] = useState(true);
  const [headerTitle, setHeaderTitle] = useState<string>("Update Appointment");

  console.log("leadEvent logged here", leadEvent);
  const methods = useForm<any>({
    defaultValues: getObserverAppointmentInitValues(
      leadEvent,
      event.startAt,
      event.endAt
    ),
    resolver: yupResolver(OBSERVER_APPOINTMENT_SCHEMA),
    mode: "all",
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: "onChange",
  });

  const startDate = useWatch({
    control: methods.control,
    name: ["startTime"],
  });

  const endDate = useWatch({
    control: methods.control,
    name: ["endTime"],
  });

  const handleBackClick = () => {
    if (isFormVisible) {
      history.push({
        pathname: "/providerappointments",
        state: {
          prefStartDate: activeDay,
        }, // New route
      });
    } else {
      setHeaderTitle("Edit Appointment");
      setVisible(true);
    }
  };

  useEffect(() => {
    if (webSocketData?.athena || webSocketData?.cr) {
      if (webSocketData?.athena !== true || webSocketData?.cr !== true) {
        setDisplayBanner(true);
      } else {
        setDisplayBanner(false);
      }
    } else {
      setDisplayBanner(false);
    }
  }, [webSocketData]);

  useEffect(() => {
    if (isFormVisible) {
      setShowConfirm(false);
    } else {
      setShowConfirm(true);
    }
  }, [isFormVisible]);

  const appointmentStartDate = new Date(
    leadEvent.startDate!
  ).toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const appointmentStartTime = new Date(
    leadEvent.startDate!
  ).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const appointmentEndTime = new Date(leadEvent.endDate!).toLocaleTimeString(
    "en-US",
    {
      hour: "2-digit",
      minute: "2-digit",
    }
  );

  const startDateHandler = React.useCallback(
    (value: any) => {
      console.log("sravan [start] 1", value);
      const date = moment(value.$d);
      const time = date;
      console.log("sravan [start] date", date);

      methods.setValue("startTime", time);
      methods.trigger(["startTime"]);
      methods.setValue("startDirty", true);
      const endDate = methods.getValues("endTime");
      if (endDate) {
        const momentStartValue = moment(time, "HH:mm");
        const momentEndValue = moment(endDate, "HH:mm");
        if (
          momentEndValue.isBefore(momentStartValue) ||
          momentEndValue.isSame(momentStartValue)
        ) {
          setIsBeforeStartTime(true);
          setTimeValidate(true);
        } else {
          setTimeValidate(false);
          setIsBeforeStartTime(false);
        }
        methods.trigger("endDate");
      }
      methods.trigger("startDate");
      setTimePrestine(checkTimePrestineStatus());
    },
    [methods]
  );

  const endDateHandler = React.useCallback(
    (value: any) => {
      const date = moment(value.$d);
      const time = date;
      methods.setValue("endTime", time);
      methods.trigger(["endTime"]);
      methods.setValue("endDirty", true);
      const startDate = methods.getValues("startTime");
      if (startDate) {
        const momentStartValue = moment(startDate, "HH:mm");
        const momentEndValue = moment(time, "HH:mm");
        if (
          momentEndValue.isBefore(momentStartValue) ||
          momentEndValue.isSame(momentStartValue)
        ) {
          setIsBeforeStartTime(true);
          setTimeValidate(true);
        } else {
          setTimeValidate(false);
          setIsBeforeStartTime(false);
        }
        methods.trigger("startDate");
      }
      methods.trigger("endDate");
      setTimePrestine(checkTimePrestineStatus());
    },
    [methods]
  );

  useEffect(() => {
    if (dayjs.isDayjs(methods.getValues("endTime"))) {
      methods.setValue(
        "endTime",
        moment(methods.getValues("endTime").toDate())
      );
    }
    if (dayjs.isDayjs(methods.getValues("startTime"))) {
      methods.setValue(
        "startTime",
        moment(methods.getValues("startTime").toDate())
      );
    }
  }, [methods.getValues(["endTime", "startTime"])]);

  const checkTimePrestineStatus = () => {
    const startTimeStatus = compareTimes(
      methods.getValues(["startTime"])[0],
      event.start,
      true
    );
    const endTimeStatus = compareTimes(
      methods.getValues(["endTime"])[0],
      event.end,
      true
    );

    if (startTimeStatus && endTimeStatus) {
      return true;
    } else {
      return false;
    }
  };

  const submitForm = React.useCallback(() => {
    const prestineCheck = checkTimePrestineStatus();
    if (prestineCheck) {
      setTimePrestine(true);
    } else {
      setTimePrestine(false);
      setVisible(false);
      setHeaderTitle("Confirm New Time");
    }
  }, []);

  const submitError = React.useCallback(() => {
    methods.trigger();
  }, [methods]);

  return (
    <EditAppointmentWrapper>
      <div className="w-100 d-flex h-center">
        <div className="header-div">
          <div className="back-to h-end v-center">
            <button onClick={handleBackClick}>
              <FontAwesomeIcon className="icon-back" icon={faChevronLeft} />
            </button>
          </div>
          <div className="page-desc h-center v-center">
            <span>{headerTitle}</span>
          </div>
          <div className="back-to"></div>
        </div>
      </div>
      {displayBanner && (
        <div>
          <CrBannerError></CrBannerError>
        </div>
      )}
      <FormProvider {...methods}>
        <div className={isFormVisible ? "form-enclosure" : "d-none"}>
          <div className="main-form">
            <div className="w-100">
              <div className="edit-appt-wrap">
                <span>Edit Appointment Time</span>
              </div>
              <div className="edit-appt-details">
                <span className="lead-event-text">
                  This observation appointment scheduled with{" "}
                  <strong>{leadEvent.provider.displayName}</strong> from{" "}
                  <strong>{appointmentStartTime.toLowerCase()}</strong> to{" "}
                  <strong>{appointmentEndTime.toLowerCase()}</strong> on{" "}
                  {appointmentStartDate} will be rescheduled.
                </span>
              </div>
            </div>

            <div className="w-100">
              <div className="form-row">
                <div className="form-row-item">
                  <FormItem optional={false} label="Start Time">
                    <Controller
                      name="startTime"
                      control={methods.control}
                      render={({ field }) => (
                        <TimerTextField
                          className={"time-style"}
                          field={field}
                          errors={methods.formState.errors.startTime}
                          value={startDate}
                          onChange={(newValue: any) => {
                            startDateHandler(newValue);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 900,
                          }}
                        />
                      )}
                    />
                  </FormItem>
                </div>

                <div className="form-row-item">
                  <FormItem optional={false} label="End Time">
                    <Controller
                      name="endTime"
                      control={methods.control}
                      render={({ field }) => (
                        <TimerTextField
                          className={"time-style"}
                          field={field}
                          errors={methods.formState.errors.endTime}
                          value={endDate}
                          onChange={(newValue: any) => {
                            endDateHandler(newValue);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 900,
                          }}
                        />
                      )}
                    />
                  </FormItem>
                </div>
              </div>
            </div>

            <div className="error-time-wrapper">
              {timeValidate && (
                <>
                  <span style={{ color: "#cc3f3f" }}>
                    {isBeforeStartTime && (
                      <>The start time must be earlier than the end time.</>
                    )}
                  </span>
                </>
              )}
              {isTimePrestine && (
                <>
                  <span style={{ color: "#cc3f3f" }}>
                    The start time or end time have not changed.
                  </span>
                </>
              )}
            </div>

            <div className="submit-button">
              <button
                disabled={isBeforeStartTime || isTimePrestine}
                type="submit"
                onClick={methods.handleSubmit(submitForm, submitError)}
              >
                Update
              </button>
            </div>
          </div>
        </div>
        {showConfirm && (
          <ConfirmEdit
            isAdmin={false}
            appointment={event}
            isDriveTime={false}
            isObserver={true}
            showReasons={false}
            leadEvent={leadEvent}
          ></ConfirmEdit>
        )}
      </FormProvider>
    </EditAppointmentWrapper>
  );
};

export default React.memo(ObserverEditForm);
