import React from "react";
import "./errorMessage.css";
import Vector from "assets/Vector.svg";

interface PtoConflictErrorsProps {
  ptoStartDate: any;
  ptoEndDate: any;
}

const ptoConflictErrors: React.FC<PtoConflictErrorsProps> = ({
  ptoStartDate,
  ptoEndDate,
}) => {
  const formattedStartTime = new Date(ptoStartDate).toLocaleTimeString(
    "en-US",
    {
      hour: "2-digit",
      minute: "2-digit",
    }
  );

  const formattedEndTime = new Date(ptoEndDate).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  return (
    <div>
      <div className="d-flex">
        <div>
          <img src={Vector} alt="Loading" />
        </div>
        <div>
          <span style={{ color: "#cc3f3f" }}>
            You have a PTO/Callout appointment from{" "}
            {formattedStartTime.toLowerCase()} to{" "}
            {formattedEndTime.toLowerCase()}. You can only have an appointment
            outside of the PTO/Callout hours. Please adjust the appointment
            date/time to continue.
          </span>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ptoConflictErrors);
