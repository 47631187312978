import React, { useContext } from "react";
import "./clientRescheduleBusy.scss";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { myContext } from "features/Hooks/useContext";

interface Props {
  activeDay: string;
  path: string;
}

const ClientRescheduleBusy: React.FC<Props> = ({ activeDay, path }) => {
  const history = useHistory();
  const { setPrefDate } = useContext(myContext);
  const HandleHomeBack = () => {
    setPrefDate(activeDay);
    if (path === "appointments")
      history.push({
        pathname: "/appointments",
      });
    else {
      history.push({
        pathname: "/client-appointments",
      });
    }
  };

  const handleGoBack = () => {
    history.push({
      pathname: "/appointments",
      state: {
        scrollToForm: true,
      },
    });
  };

  console.log(activeDay, path, "[rescheduleBusy] activeDay");
  return (
    <div
      id="Reschedule-provider-busy"
      className="client-reschedule-busy-container"
    >
      <div className="client-reschedule-busy-inner-container">
        <span className="reschedule-busy-heading">
          Oh no! It looks like your provider is busy.
        </span>

        <span className="reschedule-busy-paragraph">
          Unfortunately, your provider doesn't have any available appointment
          slots within the next 14 days.
        </span>
        <span className="reschedule-busy-paragraph">
          Please reach out the customer care team at{" "}
          <a className="reschedule-busy-tag" href="tel:8888855068">
            888-885-5068
          </a>
          , Option 2 from 4:30 am-6:00 pm PST. Outside of business hours,{" "}
          <span className="cancel-late-tag" onClick={handleGoBack}>
            Contact us
          </span>{" "}
          and we'll handle it first thing in the morning.
        </span>
        <div>
          <Button
            className="reschedule-home-button"
            size="middle"
            onClick={HandleHomeBack}
          >
            {path === "appointments" ? "Home" : "All Appointments"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ClientRescheduleBusy;
