import React, { useContext } from "react";
import "./Availability.scss";
import { myContext } from "features/Hooks/useContext";

const Availabiity: React.FC<{}> = () => {
  const { carePlan } = useContext(myContext);

  console.log(carePlan, "[Availabiity] carePlan");
  return (
    <div className="container">
      <div className="secondary-container">
        <div className="Availabilty-Header">Availability</div>
        <div className="Primary-Text">
          Please provide your general availability for as many hours as possible
          throughout the week. Remember, the more hours you're available, the
          easier it is for our team to find you options. Additionally, morning
          hours generally entail shorter waiting times compared to afternoons.
        </div>
      </div>
    </div>
  );
};

export default Availabiity;
