import React, { useMemo, useState } from "react";
import { useAuth0 } from "react-auth0-spa";
import UserMenu from "./UserMenu/UserMenu";
import Logo from "assets/blue-logo.png";
import "./header.sass";
import { AvailabilityExit } from "features/Appointments/AvailabilityExit/AvailabilityExit";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

const Header = () => {
  const history = useHistory();

  const { loading, user, logout, isAuthenticated } = useAuth0();
  const [menu, setMenu] = useState(false);

  const location = useLocation();

  const [open, setOpen] = React.useState(false);

  const pathNamesToOpen = useMemo(
    () => [
      "/appointments-availability",
      "/appointments-availability-start",
      "/appointments-availability-days",
      "/appointments-availability-builder",
      "/appointments-availability-review",
      "/appointments-availability-builder-review",
      "/edit-appointments-availiability",
      "/redirect-availability",
      "/avail",
    ],
    []
  );

  const isPathToOpen = pathNamesToOpen.includes(location.pathname);

  const redirectToHome = () => {
    const newPath = user["https://corticacare.com/app_metadata"]?.provider
      ? "/providerappointments"
      : "/appointments";
    history.push({ pathname: newPath });
  };

  const handleLogoClick = () => {
    if (location.pathname !== "/appointments-availability-close") {
      isPathToOpen ? setOpen(true) : redirectToHome();
    }
  };

  return (
    <div className="header">
      <div className="logo">
        <img src={Logo} alt="cortica-logo" onClick={handleLogoClick} />
      </div>
      {!loading && isAuthenticated && (
        <div className="info" onClick={() => setMenu(!menu)}>
          <UserMenu
            name={user.nickname}
            email={user.email}
            logoutAction={logout}
            menu={menu}
          />
        </div>
      )}
      {isPathToOpen && <AvailabilityExit open={open} setOpen={setOpen} />}
    </div>
  );
};

export default Header;
