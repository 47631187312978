import * as Yup from "yup";

export const Availability_ADD_SCHEMA = {
  allFields: "Fill out the Location and Start and End time",
  locationFields: "Select at least one Location",
  timeFieldsStartAndEnd: "Fill out Start and End time",
  timeFieldEnd: "End Time is Greater than Start Time",
  timeFieldSame: "Both Start Time and End Time are same",
  TimeFieldOverlaps: "New time overlaps with existing time",
  TimeFieldWithin:
    "One or both times do not fall within clinic hours 8:00 am - 6:00 pm",
  successfullValidation: "",
};

export const Inclinic_Checked = [2, 3, 6, 7];

export const Home_Checked = [4, 5, 6, 7];

export const CONTACT_FORM_SCHEMA = Yup.object().shape({
  firstName: Yup.string().nullable().required("Provide your first name"),
  lastName: Yup.string().nullable().required("Provide your last name"),
  contactReason: Yup.string().nullable().required("Select a reason"),
  subject: Yup.string().nullable().required("Please provide a subject"),
  description: Yup.string().nullable().required("Please provide a description"),
});
