import React from "react";
import Dialog from "@mui/material/Dialog";
import X from "assets/X.svg";
import "./availabilityExit.scss";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";

export const AvailabilityExit = ({ open, setOpen }) => {
  const history = useHistory();

  const HandleHomeNavigation = () => {
    history.push({
      pathname: "/appointments",
    });
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialogue-Exit"
      >
        <div className="dialogue-container">
          <div className="dialogue-X-img">
            <img src={X} alt="Loading" onClick={handleClose} />
          </div>
          <div className="dialogue-Heading">
            Are you sure you want to leave ?
          </div>
          <div className="dialogue-paragraph">
            Any availability that has been added or edited will not be saved
            until you click Submit.
          </div>
          <div className="dialogue-paragraph">
            If you need help, please contact the Scheduling Team at 888-885-5068
            or scheduling@corticacare.com.
          </div>
          <div className="button">
            <Button
              className="Warning-button"
              variant="contained"
              onClick={handleClose}
            >
              Keep Editing
            </Button>
            <u className="Home" onClick={HandleHomeNavigation}>
              Go Home
            </u>
          </div>
        </div>
      </Dialog>
    </>
  );
};
