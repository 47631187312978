import styled from 'styled-components';

export const FormItemWrap = styled.div`
  width: 100%;
  display: block;
  margin-bottom: 16px;
  .top-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  label {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    padding-bottom: 10px;
  }
  .optional {
    font-size: 12px;
    letter-spacing: -0.4px;
    text-align: right;
    color: #888a8c;
  }
  .form-item {
    width: 100% !important;
  }
  &.ant-col {
    width: 100% important;
  }
`;