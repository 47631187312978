import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import styled from 'styled-components';

const StyledErrorMessage = styled.div`
  color: red;
`;
export const FormError = (props) => {
  const {errors} = props;
  if (props.name)
    return (
      <StyledErrorMessage>
        <ErrorMessage
          name={props.name}
          // errors={errors?.message}
        >
            { errors?.message &&  <div>{errors?.message}</div>}

        </ErrorMessage>
      </StyledErrorMessage>
    );

  return null;
};