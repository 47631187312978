import React, { useState } from "react";
import { FormError } from "./error";
import "../reactHookComponents/textField.scss";

export const TextAreaField = ({ field, errors, ...restProps }) => {
  return (
    <div className="text-input-custom">
      {field?.name && <textarea {...field} {...restProps} />}
      {field?.name && <FormError name={field.name} errors={errors} />}
    </div>
  );
};
