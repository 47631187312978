import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import { useAuth0 } from "react-auth0-spa";
import { useHistory, useLocation } from "react-router-dom";
import Logo from "assets/blue-logo.png";
import { AvailabilityExit } from "features/Appointments/AvailabilityExit/AvailabilityExit";
import "./header.sass";
import { myContext } from "features/Hooks/useContext";
import { useIsAuthenticated as useMsalIsAuthenticated } from "@azure/msal-react";
// import { msalRoutes } from "constant/AppConstants";
import { useMsal } from "@azure/msal-react";

const Header = () => {
  const history = useHistory();
  const { confirmLoading } = useContext(myContext);
  const { loading, user, logout, isAuthenticated } = useAuth0();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { instance, inProgress } = useMsal();

  const [msProgress, setMSProgress] = useState(false);

  useEffect(() => {
    if (inProgress !== "none") {
      setMSProgress(true);
    } else {
      setMSProgress(false);
    }
  }, [inProgress]);

  const msalIsAuthenticated = useMsalIsAuthenticated();

  const activeAccount = msalIsAuthenticated
    ? instance.getActiveAccount()
    : null;

  const toggle = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (menuOpen && menuRef.current && !menuRef.current.contains(e.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [menuOpen]);

  const pathNamesToOpen = useMemo(
    () => [
      "/appointments-availability",
      "/appointments-availability-start",
      "/appointments-availability-days",
      "/appointments-availability-builder",
      "/appointments-availability-review",
      "/appointments-availability-builder-review",
      "/edit-appointments-availiability",
      "/redirect-availability",
      "/avail",
    ],
    []
  );

  const msAuthenticated = !msProgress && msalIsAuthenticated && activeAccount;
  const auth0Authenticated = !loading && isAuthenticated && !msAuthenticated;

  const handleLogout = async () => {
    const activeAccount = instance.getActiveAccount();

    if (activeAccount) {
      try {
        console.log("Logout");
        instance.setActiveAccount(null);
        await instance.logoutRedirect({
          account: activeAccount,
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const isPathToOpen = pathNamesToOpen.includes(location.pathname);

  const redirectToHome = () => {
    const newPath = user["https://corticacare.com/app_metadata"]?.provider
      ? "/providerappointments"
      : "/appointments";
    history.push({ pathname: newPath });
  };

  const handleLogoClick = () => {
    if (location.pathname !== "/appointments-availability-close") {
      isPathToOpen ? setOpen(true) : redirectToHome();
    }
  };

  const pathToIdMapping = {
    "/client-reschedule-confirm": "Reschedule-confirm-abandonment",
  };

  const imgId =
    confirmLoading === true
      ? "Reschedule-loading-abandonment"
      : pathToIdMapping[location.pathname] || "cortica-logo";

  return (
    <div className="header">
      <div className="logo">
        <img
          id={imgId}
          src={Logo}
          alt="cortica-logo"
          onClick={handleLogoClick}
        />
      </div>

      <div ref={menuRef} className="info" onClick={toggle}>
        {menuOpen && (
          <div className="sign-out">
            <div className="user-info">
              {msAuthenticated && <span>{activeAccount?.name}</span>}
              {auth0Authenticated && <span>{user.email}</span>}
            </div>
            {msAuthenticated && <span onClick={handleLogout}>Log Out</span>}
            {auth0Authenticated && <span onClick={logout}>Log Out</span>}
          </div>
        )}
        <div className="toggle-wrapper">
          <div className="username">
            {msAuthenticated && (
              <>
                <p>{activeAccount?.username.charAt(0).toUpperCase()}</p>
              </>
            )}
            {auth0Authenticated && (
              <>
                <p>{user.nickname.charAt(0).toUpperCase()}</p>
              </>
            )}
          </div>
        </div>
      </div>

      {isPathToOpen && <AvailabilityExit open={open} setOpen={setOpen} />}
    </div>
  );
};

export default Header;
