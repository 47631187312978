import React from "react";
import Loading from "assets/loading.svg";
import "./spinnerV2.sass";

const SpinnerV2 = () => {
  return (
    <div className="loader-wrapper-V2">
      <div className="loader-V2">
        <img className="spin-V2" src={Loading} alt="spinner" />
      </div>
    </div>
  );
};

export default SpinnerV2;
