import React from "react";
import { useAuth0 } from "react-auth0-spa";
import logutIcon from "../../assets/logout-icon.png";
import "./Logout.css";
const Logout = () => {
  const { loginWithRedirect, logout } = useAuth0();

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
    loginWithRedirect({});
  };
  return (
    <div className="logout-container">
      <h2>You're logged out</h2>
      <img src={logutIcon} alt="characters waving goodbye and walking away" />
      <div className="logout-text-container">
        <h3 className="logout-heading">Did you know?</h3>
        <p className="logout-body">
          With the right support, many autistic individuals are able to
          independently hold a variety of jobs. Many have mental strengths and
          strong interests that can lead to a fulfilling jobs later in life.
        </p>
        <button className="btn-primary" onClick={handleLogout}>
          Log In
        </button>
      </div>
    </div>
  );
};

export default Logout;
